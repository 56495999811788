import {fetch} from 'whatwg-fetch'

function ready (fn) {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

function biss_get_filters() {
  var sterm = document.getElementById('sterm').value;
  var alleWorter = document.getElementById('alleWorter').checked;
  var alleSeiten = document.getElementById('alleSeiten').checked;
  var dokumente = document.getElementById('dokumente').checked;
  var loggedIn = document.getElementById('is_user_logged_in').value
  var inputtext = document.getElementById('sterm')

  
  
  var filters = {
    sterm: sterm,
    alleWorter: alleWorter,
    alleSeiten: alleSeiten,
    dokumente: dokumente,
    uli: loggedIn
  }
  
  return filters
}

function biss_search_filter() {
  if(window.location.href.match("/suche/")) { 
    var form = document.getElementById("searchform");
     //var inputtext = document.getElementById('sterm')
     var resetBtton = document.getElementById('zurucksetzen')
    var alleSeitenBox = document.getElementById('alleSeiten')
    var dokumenteBox = document.getElementById('dokumente')
    var alleWorterBox = document.getElementById('alleWorter');
    var docs = "off"
    var allSite = "off"
    var allWorter = "off"

    
    var queryString = ""
    
    if ( form !== null ){
      form.addEventListener("submit", function(e) {
        e.preventDefault()
        var filters = biss_get_filters()
        
          
          
          let stateObj = {
            foo: "bar",
          };
          
          if ( alleWorterBox.checked !== false){
            allWorter = "on"
          } else {
            allWorter = "off"
          }
          if ( dokumenteBox.checked !== false ){
             docs = "on"
          } else {
            docs = "off"
          }
          if ( alleSeitenBox.checked !== false ){
            allSite = "on"
          } else {
            allSite = "off"
          }

          queryString = '?sterm='+filters['sterm']+'&alleWorter='+allWorter+'&alleSiten='+allSite+'&dokumente='+docs

         
          history.pushState(stateObj, "suche", queryString);
          
          biss_search_fetch(filters)
          //return false
      });
     
      resetBtton.addEventListener('click', function(event){
        var inputtext2 = document.getElementById('sterm')
        inputtext2.value = '';
        alleSeitenBox.checked = true
        dokumenteBox.checked = true
        alleWorterBox.checked = false
        event.preventDefault()
        var filters = biss_get_filters()
        biss_search_fetch(filters)
      } )


      // inputtext.addEventListener('keyup', function(event){
      //   if(event.keyCode === 13){
      //     event.preventDefault();
      //     var filters = biss_get_filters()
      //     biss_search_fetch(filters)
      //   }
      // })
    }
    
    var filters = biss_get_filters()
    
    biss_search_fetch(filters)
  }
}

function biss_search_fetch(filters){
  var container = document.getElementById('search_results')
  var resFound = document.getElementById('resFound')
  var stermd = document.getElementById('stermd')



  container.innerHTML = ''
  
  fetch('/wp-json/biss/v1/search', {
    body: JSON.stringify(filters),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(function(response){
    return response.json()
  }).then(function(response){
    container.innerHTML = response.html
      resFound.innerHTML = response.found
    stermd.innerHTML = response.sterm
  }).catch(function(error){
    console.error(error)
  })
}


ready(biss_search_filter)




import {fetch} from 'whatwg-fetch'

function ready (fn) {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

function filter () {

  var toolForm = document.getElementById('tool-form');
  if ( toolForm ) {


  var e_moduls = []
  var p_moduls = []
  var s_moduls = []

  var filters = {
    e_modul: e_moduls,
    p_modul: p_moduls,
    s_modul: s_moduls,
    bildungsetappe: '',
    zweck: '',
    stichpunkt: ''
  }

  //fetchData(filters)

  var triggers = document.querySelectorAll('[data-filter]')
  var zweckDropDown = document.getElementById('zweckDropDown')
  var setupDropDown = document.getElementById('bildungsetappeDropDown')
  var resetButton = document.getElementById('reset-button')

    var initialZweck = zweckDropDown.innerHTML.trim();
    var initialBildungsetappe = setupDropDown.innerHTML.trim();
    filters.zweck = (!!initialZweck && initialZweck.trim() !== '--') ? initialZweck : '';
    filters.bildungsetappe = (!!initialBildungsetappe && initialBildungsetappe !== '--') ? initialBildungsetappe : '';

  if ( triggers !== null ){
    Array.prototype.forEach.call(triggers, function(trigger){
      trigger.addEventListener('click', function(event){
        //event.preventDefault()

        var filter = trigger.getAttribute('data-filter')
        var target = trigger.getAttribute('data-target')

        if ( filter === 'e_modul' || filter === 'p_modul' || filter === 's_modul' ){
          if(filters[filter].indexOf(target) === -1 ){
            //this.classList.add('active');
            filters[filter].push(target)
          } else {
            //this.classList.remove('active');
            filters[filter].splice(filters[filter].indexOf(target), 1)
          }
          
          //console.log(filters[filter])
        } else{
          filters[filter] = target
          if ( filter === 'zweck' ){
            zweckDropDown.innerHTML = target
          } else if ( filter === 'bildungsetappe' ){
            setupDropDown.innerHTML = target
          }
        }

        console.log( filters )
        // fetchToolData(filters)



      })
    })
  }

  var form_el = document.getElementById("tool-form");
  var input = document.getElementById('stichpunkt')

  var container = document.getElementById('tool-results')

  if ( form_el !== null ){
    form_el.addEventListener("submit", function(evt) {
        evt.preventDefault()
  
        filters.stichpunkt = input.value
  
  
        fetchToolData(filters)
    });
  }

  if ( resetButton !== null ){
    resetButton.addEventListener('click', function(event){
      zweckDropDown.innerHTML = '--'
      setupDropDown.innerHTML = '--'
      e_moduls = []
      p_moduls = []
      s_moduls = []
      filters['e_modul'] = e_moduls
      filters['p_modul'] = p_moduls
      filters['s_modul'] = s_moduls
      filters['bildungsetappe'] = ''
      filters['zweck'] = ''
      filters['stichpunkt'] = ''
      container.innerHTML = ''
    })
  }

    fetchToolData(filters)
}

  
}

function fetchToolData(filters){
  var container = document.getElementById('tool-results')

   if (container !== null) {
    container.innerHTML = '<div class="ref-preloader"><div class="preloader"><img src="/wp-content/themes/biss/assets/img/Spin-1s-200px.gif" alt="Preloader"></div></div>'
  }

  fetch('/wp-json/biss/v1/tool', {
    body: JSON.stringify(filters),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(function(response){
    return response.json()
  }).then(function(response){
    
    if( response.hasOwnProperty('html') && container !== null ){
        container.innerHTML = response.html
    }
    
  }).catch(function(error){
    console.error(error)
  })
}


ready(filter)

import {fetch} from 'whatwg-fetch'

function ready (fn) {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

function filter () {

  var morenews = document.getElementById('more-news-container')
  
  if ( morenews ) {
    
    var featuredPosts = document.querySelectorAll('.featured-post')
    var notInArray = []

    Array.prototype.forEach.call( featuredPosts, function(post){
      var dataID = post.getAttribute('data-id')
      notInArray.push(dataID)
    } )

  var filters = {
    posts_per_page: 8,
    post__not_in: notInArray
  }

  fetchNews(filters)

  var moreButton = document.getElementById('load-more-news')

  moreButton.addEventListener('click', function(event){
    event.preventDefault();
    filters['posts_per_page'] = filters['posts_per_page'] + 5
    fetchNews(filters)
  })

  





}

  
}

function fetchNews(filters){
  var container = document.getElementById('more-news-container')

   if (container !== null) {
     //container.innerHTML = '<div class="ref-preloader"><div class="preloader"><img src="/wp-content/themes/jungmut/assets/img/spinneravito.gif" alt="Preloader"></div></div>'
  }

  fetch('/wp-json/biss/v1/more-news', {
    body: JSON.stringify(filters),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(function(response){
    return response.json()
  }).then(function(response){
    
    if( response.hasOwnProperty('html') && container !== null ){
        container.innerHTML = response.html
    }
    
  }).catch(function(error){
    console.error(error)
  })
}


ready(filter)

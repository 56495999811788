import {fetch} from 'whatwg-fetch'


import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/scss/fontawesome.scss'
import '@fortawesome/fontawesome-free/scss/solid.scss'


import Swiper from 'swiper/dist/js/swiper.js'


import './map'
import './verbunde-search'
import './search'
import './tool-search'
import './more-news'

var mySwiper = new Swiper('.news-slider', {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  
 });


 var imageGallerys = document.querySelectorAll('.image-gallery')
 var nextArrows = document.querySelectorAll( '.gallery-button-next' )
 var prevArrows = document.querySelectorAll( '.gallery-button-prev' )

 if ( imageGallerys ){

  Array.prototype.forEach.call( nextArrows, function(nextArrow, index){

    nextArrow.classList.add( 'gb-next'+index )

  })

  Array.prototype.forEach.call( prevArrows, function(prevArrow, index){

    prevArrow.classList.add( 'gb-prev'+index )

  })

  Array.prototype.forEach.call( imageGallerys, function(imgGallery, index){

    imgGallery.classList.add( 'img-slide-' +index)

    var mySwiper = new Swiper('.img-slide-'+index, {
      navigation: {
        nextEl: '.gb-next'+index,
        prevEl: '.gb-prev'+index,
      },
      pagination: {
        el: '.gallery-pagination',
        clickable: true
      },
      
     });


  })

 }

var mySwiper = new Swiper('.box-slider', {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  
 });
var mySwiper = new Swiper('.offers-slider', {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slidesPerView: 2,
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  breakpoints: {
    500: {
      slidesPerView: 1,
    },
   
  }
  
 });


 var mobilButton = document.getElementById('menu-button')
 var menuWrapper = document.getElementById('menu-wrapper')
 var mobilSearch = document.getElementById('mobil-search')
 var parentMenus = document.querySelectorAll('.menu-item-has-children')

 mobilButton.addEventListener('click', function(event){
   if( mobilButton.classList.contains('close-button') ){
      mobilButton.classList.remove('close-button')
      menuWrapper.classList.remove('active')
      mobilSearch.classList.remove('menu-active')
      Array.prototype.forEach.call(parentMenus, function(parent){
        parent.children[1].classList.remove('open')
        parent.classList.remove('open')
      })
   } else {
     mobilButton.classList.add('close-button')
     menuWrapper.classList.add('active')
     mobilSearch.classList.add('menu-active')

   }
 })
 
 if (window.matchMedia("(max-width: 992px)").matches){
  
   Array.prototype.forEach.call(parentMenus, function(parent){
     parent.addEventListener('click', function(event){
      // event.preventDefault()
       
       if (parent.children[1].classList.contains('open')){
         parent.children[1].classList.remove('open')
         parent.classList.remove('open')
       } else {
          Array.prototype.forEach.call(parentMenus, function(parent){
            parent.children[1].classList.remove('open')
            parent.classList.remove('open')
          })
         parent.children[1].classList.add('open')
         parent.classList.add('open')

       }
     })
   })
 }

 var anchors = document.querySelectorAll('a[href^="#"]')
 Array.prototype.forEach.call(anchors, function(anchor){
   anchor.addEventListener('click', function(e){
    if(this.getAttribute('href') != '#') {
      e.preventDefault();
      if ( this.classList.contains('internlink') || this.classList.contains('nobrlink') ){
        var itemName = this.getAttribute('href')
        var aid = itemName.split('#')
        document.querySelector('a[name=' + aid[1] +']').scrollIntoView({ behavior: 'smooth' })
      } else{
        var itemName = this.getAttribute('href')
        var aid = itemName.split('#')
        if(document.querySelector(this.getAttribute('href'))) {
          document.querySelector(this.getAttribute('href')).scrollIntoView({ behavior: 'smooth' })
        } else if(document.querySelector('a[name=' + aid[1] +']')) {
          document.querySelector('a[name=' + aid[1] +']').scrollIntoView({ behavior: 'smooth' })
        } else {
          return;
        }
      }

    }
   })
 })


  
jQuery(document).ready(function() {
  
  jQuery('a.jetztanmelden').click(function(e){
    e.preventDefault()
    jQuery('#anmeldungform').slideToggle();
    if(jQuery(this).hasClass('opened')){
      jQuery(this).removeClass('opened')
    } else {
      jQuery(this).addClass('opened')
      jQuery('.gform_wrapper select').select2()
    } 
  })
  
  function gravityFocusBlur() {
    jQuery('.gform_wrapper input').focus(function(){
     jQuery(this).parents('.gfield').addClass('focused');
    });

    jQuery('.gform_wrapper input').blur(function(){
      gravityFilled();
    })
  }
  
  function gravityFilled() {
    jQuery('.gform_wrapper :input').each( function() {
      var inputValue = jQuery(this).val()
      if ( inputValue == "" ) {
        jQuery(this).parents('.gfield').removeClass('filled')
        jQuery(this).parents('.gfield').removeClass('focused')
      } else {
        jQuery(this).parents('.gfield').addClass('filled')
      }
    })
    
  }
  
  gravityFocusBlur()
  gravityFilled()
  
  
  jQuery('.gform_wrapper select').select2()
  jQuery(document).bind('gform_post_render', function(){
   jQuery('.gform_wrapper select').select2()
   gravityFocusBlur()
   gravityFilled()
  })
  
  jQuery('#hsearchbut,#hsearchbut_m').click(function(e){
    e.preventDefault()
    jQuery('header .sform').toggle()
  })
  
  jQuery(document).click(function(e) {
    var container = jQuery("header .search")
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      jQuery('.search .sform').hide()
    }
  })
  
  jQuery('#zurucksetzen').click(function(e){
    e.preventDefault();
    jQuery('#searchform input#sterm').attr('value','')
    jQuery('#searchform input#alleWorter').attr('checked',false)
  })
  
})


var base_url = window.location.origin;
var grunerPunkts = document.querySelectorAll('img[title="grüner Punkt"]')
var gelberPunkts = document.querySelectorAll('img[title="gelber Punkt"]')

Array.prototype.forEach.call( grunerPunkts, function(grunerPunkt){
  grunerPunkt.src = base_url + '/wp-content/themes/biss/assets/img/GruenerPunkt.png'
} )
Array.prototype.forEach.call( gelberPunkts, function(gelberPunkt){
  gelberPunkt.src = base_url + '/wp-content/themes/biss/assets/img/GelberPunkt.png'
} )

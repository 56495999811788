import {fetch} from 'whatwg-fetch'

var mapContinaer = document.getElementById('verbuendemap')

if (mapContinaer){

var map = L.map('verbuendemap', {scrollWheelZoom:false, minZoom: 6}).setView([51.1642292, 10.4541194], 6);
var themeImgDir = '/wp-content/themes/biss/assets/img/map/'
var icnSize = [12, 12]



L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
}).addTo(map);


var iconClaret = L.icon({
  iconUrl: themeImgDir+'re_claret.png',
  iconSize: icnSize
});

var iconOrange = L.icon({
  iconUrl: themeImgDir+'re_orange.png',
  iconSize: icnSize
});

var iconTurquoise = L.icon({
  iconUrl: themeImgDir+'re_turquoise.png',
  iconSize: icnSize
});

var iconYellow = L.icon({
  iconUrl: themeImgDir+'re_yellow.png',
  iconSize: icnSize
});

var iconDefault = L.icon({
  iconUrl: themeImgDir+'../marker-icon.png',
  iconSize: icnSize
});

fetch('/wp-json/verbuende/markers')
.then(function(response) {
 return response.json()
})
.then(function(markersjson) {
 for ( var i=0; i < markersjson.length; ++i ) {

   var markerIcon,mType,mTypeClass;

   if(markersjson[i].bildungsetappe === 1) {
     markerIcon = iconClaret
     mType = 'Elementarbereich'
     mTypeClass = 'type1'
   } else if (markersjson[i].bildungsetappe === 2) {
     markerIcon = iconOrange
     mType = 'Primarstufe'
     mTypeClass = 'type2'
   } else if (markersjson[i].bildungsetappe === 3) {
     markerIcon = iconTurquoise
     mType = 'Sekundarstufe'
     mTypeClass = 'type3'
   } else if (markersjson[i].bildungsetappe === 4) {
     markerIcon = iconYellow
     mType = 'Elementarbereich & Primarstufe'
     mTypeClass = 'type4'
   } else {
     markerIcon = iconDefault
     mType = ''
     mTypeClass = 'typeDefault'
   }

   var marker = L.marker(
     [markersjson[i].lng,markersjson[i].lat],
     {
       icon: markerIcon,
     }
   ).addTo(map);

   var popupContent = '<a href="'+markersjson[i].url+'"><b>'+markersjson[i].bundesland+'</b> - '+markersjson[i].name+'</a>';

   marker.bindPopup(popupContent,{className: mTypeClass});
 }
})

}